import * as React from 'react';
import Body from '../components/body';
import Container from '../components/container';
import Layout from '../components/layout';
import PageTitle from '../components/page-title';
import Seo from '../components/seo';
const contactMethods = [
  {
    label: 'telephone',
    value: '07875 403 292',
    link: 'tel:+447875403292',
  },
  {
    label: 'email',
    value: 'leanne.jane.wright@gmail.com',
    link: 'mailto:leanne.jane.wright@gmail.com',
  },
  {
    label: 'facebook messenger',
    value: 'Halo Hair Derbyshire',
    link: 'https://m.me/Halohairderbyshire',
  },
];

const ContactPage = ({ transitionStatus, entry, exit }) => (
  <Layout transitionStatus={transitionStatus}>
    <Seo
      title='Contact'
      description='Contact information for Halo Hair Derbyshire - Mobile Hairdressing'
    />
    <div className='my-20'>
      <Container transitionStatus={transitionStatus} entry={entry} exit={exit}>
        <Body>
          <PageTitle title='Contacting me' />
          <p className='px-10 py-6 md:px-20'>
            If you’d like to book an appointment you can contact me using one of
            the methods below
          </p>
          {contactMethods.map((method) => (
            <div className='mt-12' key={method.label}>
              <div className='mb-1'>{method.label}</div>
              <a
                className='text-lg font-bold md:font-normal md:text-3xl hover:bg-dark-salmon transition duration-500 ease-in-out'
                href={method.link}
              >
                {method.value}
              </a>
            </div>
          ))}
        </Body>
      </Container>
    </div>
  </Layout>
);

export default ContactPage;
